<template>
    <v-container fluid>
        <v-card flat>
            <v-card-title class="text-h4">Configure Kiosk</v-card-title>
        </v-card>
        <v-card flat :height="configureKioskDimension.outerCardHeight" class="mt-3">
            <v-row no-gutters class="fill-height">
                <v-col cols="4" class="px-10" align-self="center" align="center">
                    <span class="text-h6">Application Configuration</span>
                    <v-card flat :height="configureKioskDimension.innerCardHeight" class="overflow-y-auto">
                        <v-form ref="cmsConfigKioskForm" lazy-validation class="pa-6">
                            <v-text-field v-model="config.cmsLink" label="CMS Address URL (https://...)"
                                :rules="rules.cmsAddressRules" required outlined dense>
                            </v-text-field>
                            <v-text-field v-model="config.videoLink" label="Telepresence URL (https://...)"
                                :rules="rules.videoLinkRules" required outlined dense>
                            </v-text-field>
                            <v-select :items="getClientConfig.courtInfo" v-model="config.locationCode" dense outlined
                                label="Kiosk Location Code" item-text="alias"></v-select>
                            <v-row no-gutters>
                                <v-col cols="6" class="mt-2">
                                    <span class="font-weight-bold">Kiosk premise location</span>
                                </v-col>
                                <v-col cols="6">
                                    <v-select :items="kioskPremise" v-model="config.kioskPremise" dense outlined
                                        label="Kiosk premise location"></v-select>
                                </v-col>
                            </v-row>
                            <v-text-field
                                label="Default Office Location (Add map location here for user to send Ex. Clerk's Office)"
                                v-model="config.defaultLocation" outlined dense></v-text-field>
                            <v-select v-model="config.languages" multiple :items="getClientConfig.languages"
                                item-text="name" item-value="code" outlined dense label="Select Kiosk Languages"></v-select>
                            <v-select v-model="config.modules" multiple :items="getClientConfig.modules" item-text="name"
                                item-value="alias" outlined dense label="Select Kiosk Modules"></v-select>
                            <v-switch v-model="config.telepresence" label="Telepresence" color="green" hide-details dense
                                class="ml-5"></v-switch>
                            <v-switch v-model="config.physicalKeyboard" label="Physical Keyboard" color="green" dense
                                hide-details class="ml-5"></v-switch>
                            <v-switch v-model="config.showMicrophone" label="Show Microphone" color="green" dense
                                hide-details class="ml-5"></v-switch>
                        </v-form>
                    </v-card>
                </v-col>
                <v-col cols="4" class="px-10" align-self="center" align="center">
                    <span class="text-h6">Avatar Configuration</span>
                    <v-card flat :height="configureKioskDimension.innerCardHeight" class="overflow-y-auto">
                        <v-row no-gutters align="center" justify="center" class="my-3">
                            <v-col cols="4" v-if="selectedAvatar !== null && selectedAvatar !== undefined">
                                <v-img :src="getCurrentAvatarImage()" contain></v-img>
                            </v-col>
                            <v-col cols="3" align-self="center"
                                v-if="selectedAvatar !== null && selectedAvatar !== undefined">
                                <span class="text-subtitle-1 font-weight-bold">{{ selectedAvatar.avatarName }}</span>
                            </v-col>
                            <v-col cols="12" align="center" class="my-4">
                                <v-btn v-if="selectedAvatar === null || selectedAvatar === undefined" color="primary"
                                    width="150" @click="selectAvatar()">
                                    Select Avatar
                                </v-btn>
                                <v-btn v-else color="primary" width="150" @click="updateAvatar()">
                                    Update Avatar
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card>

                </v-col>
                <v-col cols="4" class="px-10" align-self="center" align="center">
                    <span class="text-h6">QNA Configuration</span>
                    <v-card flat :height="configureKioskDimension.innerCardHeight" class="overflow-y-auto">
                        <v-switch v-model="config.englishQna.enable" label="Enable English" class="px-6"></v-switch>
                        <v-form ref="avatarConfigKioskForm" lazy-validation class="px-6">
                            <v-text-field v-model="config.englishQna.kbName" label="Enter Azure KB name"
                                :rules="rules.generalFieldRule" required outlined dense>
                            </v-text-field>
                        </v-form>
                        <v-switch v-model="config.spanishQna.enable" label="Enable Spanish" class="px-6"></v-switch>
                        <v-form ref="avatarConfigKioskForm" lazy-validation class="px-6" v-if="config.spanishQna.enable">
                            <v-text-field v-model="config.spanishQna.kbName" label="Enter Azure KB name"
                                :rules="rules.generalFieldRule" required outlined dense>
                            </v-text-field>
                        </v-form>
                    </v-card>
                </v-col>
                <v-col cols="12" align="center">
                    <v-btn color="primary" width="200" @keyup.enter="saveKioskConfig" @click="saveKioskConfig">Save
                        Configuration</v-btn>
                </v-col>
            </v-row>
        </v-card>

    </v-container>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    name: 'configureKiosk',
    data() {
        return {
            kioskPremise: ['Inside', 'Outside'],
            config: {
                englishQna: {
                    enable: true,
                    kbName: ""
                },
                spanishQna: {
                    enable: false,
                    kbName: ""
                },
                kId: null,
                name: "",
                avatarInfo: {},
                locationCode: "",
                defaultLocation: "",
                courts: [],
                languages: [],
                modules: [],
                telepresence: false,
                physicalKeyboard: false,
                showMicrophone : true,
                configured: false,
                kioskPremise: ""
            },
            rules: {
                cmsAddressRules: [(v) => !!v || "CMS address is required"],
                hostAddressRules: [(v) => !!v || "Host address is required"],
                routeRules: [(v) => !!v || "Route is required"],
                endpointRules: [(v) => !!v || "Endpoint key is required"],
                videoLinkRule: [(v) => !!v || "Telepresence URL is required"],
                generalFieldRule: [(v) => !!v || "Field cannot be left empty"],
            }
        }
    },
    computed: {
        ...mapGetters(['getClientConfig', 'selectedKiosk', 'avatarList', 'configureKioskDimension', 'selectedAvatar'])
    },
    methods: {
        saveKioskConfig() {
            if (
                this.$refs.cmsConfigKioskForm.validate() &&
                // this.$refs.chatbotConfigKioskForm.validate() &&
                this.$refs.avatarConfigKioskForm

            ) {
                this.loading = true;
                console.log('New Config: ', this.config)
                this.$store.dispatch("saveKioskConfig", this.config).then(
                    (response) => {
                        this.loading = false;
                        this.show = !this.show;
                        this.$store.dispatch("showSnackBar", {
                            text: response,
                            color: "success",
                        });
                    },
                    (error) => {
                        this.$store.dispatch("showSnackBar", {
                            text: error,
                            color: "error",
                        });
                    }
                );
            }
        },
        getCurrentAvatarImage() {
            console.log('Selected Kiosk: ', this.selectedAvatar)
            if (this.selectedAvatar !== null && this.selectedAvatar !== undefined) {
                console.log('Coming in here: ', this.selectedAvatar)
                this.avatarName = this.selectedAvatar.avatarName
                let temp = this.avatarList.filter(ava => ava.avatarId === this.selectedAvatar.avatarId)
                return 'https://strapi.arsconnect.com' + temp[0].avatarImage.url
            }
        },
        selectAvatar() {
            this.$store.commit('setSelectAvatarViewer', true)
        },
        updateAvatar() {
            console.log('Selected AVatar : ', this.selectedAvatar)
            let data = {
                avatarId: this.selectedAvatar.avatarId,
                sitepalId: this.selectedAvatar.sitepalId,
                avatarName: this.selectedAvatar.avatarName,
                name: this.config.name,
                id: this.config.kId
            }
            this.$store.commit('openAvatarConfigViewer', {
                show: true,
                kioskObject: data
            })
        }
    },
    mounted() {
        console.log('Selected Kiosk at mounted: ', this.selectedKiosk.data())
        this.config = this.selectedKiosk.data();
        this.config.kId = this.selectedKiosk.id;
        this.$store.commit('setSelectedAvatar', this.selectedKiosk.data().avatarInfo)
        console.log('Config Object at mounted: ', this.config)
    }
}
</script>